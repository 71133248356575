import React from "react"
// import { StaticImage } from "gatsby-plugin-image"
import Seo from '../components/seo'
import Layout from '../components/layout'
import Footer from '../components/footer'

import axios from "axios"
import { Formik, Field, Form } from 'formik'
import { PhoneMask } from '../forms/components'
import { InboxIcon, PhoneIcon } from "@heroicons/react/outline"

import '../styles/forms.css'

const encode = (data) => {
  return Object.keys(data)
  .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
  .join("&")
}

const post_form = async (values) => {
  return await axios({
    method: 'POST',
    url: '/',
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    data: encode({ "form-name": "contact", ...values })
  })
  .then(() => (true))
  .catch(err => {
    alert(err.message)
    return false
  })
}

const ContactForm = () => {
	return (
    <div className="w-full md:w-4/5">
      <Formik
        initialValues={{
          first: '',
          last: '',
          company: '',
          email: '',
          phone: '',
          comments: ''
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(post_form(values))
        }}
      >
        {({ isSubmitting, errors, values }) => {
          const default_btn_class = "w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-darkskyblue hover:bg-skyblue md:py-4 md:text-lg md:px-10"
          const btn_class = isSubmitting ? (default_btn_class + " disabled") : default_btn_class
          return (
            <Form>
              <div className="pb-12 pt-6 text-xl md:text-2xl">
                <div className="w-full pb-4">
                  <PhoneIcon className="inline-block w-12 h-12" /> <a className="hover:text-seagreen" href="tel:12693121723">269.312.1723</a>
                </div>
                <div className="w-full pb-4">
                  <InboxIcon className="inline-block w-12 h-12" /> <a className="hover:text-seagreen" href="mailto:csobeck@pyroclasti.cloud">csobeck@pyroclasti.cloud</a>
                </div>
              </div>
              {
                isSubmitting ?
                <div className="bg-lightskyblue border border-skyblue text-darkskyblue px-4 py-3 mb-6 rounded" role="alert">
                  <span className="block sm:inline">Thanks! We shall be contacting you soon for a brief consult.</span>
                </div>
                : null
              }
              <div className="md:items-center mb-6">
                <div className="w-full">
                  <label className="block text-purple-dark font-bold mb-1 pr-4" htmlFor="field-first">
                    First Name
                  </label>
                </div>
                <div className="w-full">
                  <Field className="input-field" id="field-first" name="first" type="text" required />
                </div>
              </div>
              <div className="md:items-center mb-6">
                <div className="w-full">
                  <label className="block text-purple-dark font-bold mb-1 pr-4" htmlFor="field-last">
                    Last Name
                  </label>
                </div>
                <div className="w-full">
                  <Field className="input-field" id="field-last" name="last" type="text" required />
                </div>
              </div>
              <div className="md:items-center mb-6">
                <div className="w-full">
                  <label className="block text-purple-dark font-bold mb-1 pr-4" htmlFor="field-company">
                    Company Name
                  </label>
                </div>
                <div className="w-full">
                  <Field className="input-field" id="field-company" name="company" type="text" required />
                </div>
              </div>
              <div className="md:items-center mb-6">
                <div className="w-full">
                  <label className="block text-purple-dark font-bold mb-1 pr-4" htmlFor="field-email">
                    E-mail
                  </label>
                </div>
                <div className="w-full">
                  <Field className="input-field" id="field-email" name="email" type="email" placeholder="jane.doe@company.com" required />
                </div>
              </div>
              <div className="md:items-center mb-6">
                <div className="w-full">
                  <label className="block text-purple-dark font-bold mb-1 pr-4" htmlFor="field-phone">
                    Phone
                  </label>
                </div>
                <div className="w-full">
                  <Field className="input-field" id="field-phone" name="phone" component={PhoneMask} />
                </div>
              </div>
              <div className="md:items-center mb-6">
                <div className="w-full">
                  <label className="block text-purple-dark font-bold mb-1 pr-4" htmlFor="field-comments">
                    Questions/Comments
                  </label>
                </div>
                <div className="w-full">
                  <Field className="input-field" id="field-comments" name="comments" component="textarea" />
                </div>
              </div>
              <div className="md:items-center">
                <div className="w-full">
                  <button className={btn_class} type="submit" disabled={isSubmitting}>
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
	)
}

export default function Contact() {
  const page_title = "Contact Us"
	return (
  	<>
  		<Seo title={page_title} slug="contact" />
	  	<Layout>
	  		<main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
          <div className="sm:text-center lg:text-left">
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl py-8">
              <span className="block xl:inline">{page_title}</span>
            </h2>
          </div>
	  			<ContactForm />
	  		</main>
	  	</Layout>
	  	<Footer />
	  </>
	)
}